import {
  create,
  getAll,
  getAllByDataType,
  getById,
  remove,
  update,
} from '../../../services/apiServices/staticDataServices.js';
import ActionTypes from './actionTypes.js';

const getFilteredData = (dataType) => {
  return async (dispatch) => {
    await getAllByDataType(dataType).then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllStaticDatas(response));
    });
  };
};
const getAllStaticDatas = (data) => {
  const checkType = data[0].staticDataType;
  let newData = {};
  let name = '';
  switch (checkType) {
    case 0:
      name = 'LearningPurpose';
      newData = data;
      break;
    case 1:
      name = 'LanguageLevels';
      newData = data;
      break;
    case 2:
      name = 'KidsLangLevel';
      newData = data;
      break;
    case 3:
      name = 'TypeOfService';
      newData = data;
      break;
    case 4:
      name = 'Countries';
      newData = data;
      break;
    case 5:
      name = 'Language';
      newData = data;
      break;
    case 6:
      name = 'CountryCode';
      newData = data;
      break;
    case 7:
      name = 'CurrencyUnit';
      newData = data;
      break;
    case 8:
      name = 'DayOfWeeks';
      newData = data;
      break;
    case 9:
      name = 'MeetingLanguageLevels';
      newData = data;
      break;
    case 10:
      name = 'OrderStatus';
      newData = data;
      break;
    case 11:
      name = 'PaymentStatus';
      newData = data;
      break;
    case 12:
      name = 'PaymentType';
      newData = data;
      break;
    case 13:
      name = 'UserArea';
      newData = data;
      break;
    case 14:
      name = 'Native';
      newData = data;
      break;
    case 15:
      name = 'Gender';
      newData = data;
      break;
    case 16:
      name = 'NotificationType';
      newData = data;
      break;
    case 17:
      name = 'NotificationCategory';
      newData = data;
      break;
    default:
      return;
  }
  return {
    type: ActionTypes.staticData.GETALL_STATICDATA,
    payload: { name, newData },
  };
};
const staticDataActions = {
  getFilteredData,
};
export default staticDataActions;
