import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import { getAll, getSpeakerDetail } from '../../../services/apiServices/speakerEffortServices.js';
import ActionTypes from './actionTypes.js';

const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.speakerEffort.GetAll, payload: { data } };
};
const getDetailAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getSpeakerDetail(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.speakerEffort.GetDetailsBySpeaker,
    payload: { data },
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.speakerEffort.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.speakerEffort.HandleValidation,
    payload: data,
  };
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
      await dispatch(getByIdReducer());
    }
  };
};

const speakerEffortActions = {
  getAllAction,
  getDetailAction,
  handleError,
  handleValidationReducer,
  cleanState,
};
export default speakerEffortActions;
