import utmDataSender from '../../../hooks/useUtmDataSender.js';

import config from '../../../config.json';
import { formActions, reponseStatus, responseErrorStatus, storageKeys } from '../../../constants/Enum.jsx';
import { create, getAll, remove } from '../../../services/apiServices/subscriptionServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch, getState) => {
    const { createAction } = getState().subscription.loading;
    if (createAction) return;
    dispatch(handleRequestReducer({ createAction: true }));
    dispatch(cleanState(true, true));
    try {
      const res = await create(createData);
      const isSuccess = res?.data?.isSuccess;
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleErrorReducer(res?.data?.isSuccess));
      if (isSuccess) {
        const storageKey = sessionStorage.getItem(storageKeys.utmData);
        if (storageKey !== null && storageKey !== '' && storageKey !== undefined) {
          utmDataSender(createData, formActions.letUsCall);
        }
      }
    } catch (error) {
      await handleResponseError(dispatch, error);
    } finally {
      dispatch(handleRequestReducer({ createAction: false }));
    }
  };
};

const removeAction = (id) => {
  return async (dispatch) => {
    await remove(id).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleErrorReducer(reponseStatus.success));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(reponseStatus.fail));
      }
    });
  };
};
const getAllAction = (filterData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(filterData);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.subscription.GetAll, payload: data };
};

const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.subscription.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.subscription.HandleValidation,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.subscription.HandleRequest,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.subscription.CleanList };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};
const cleanState = (validation = false, ongoing = false, state = null) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(reponseStatus.default));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(reponseStatus.default));
      dispatch(handleErrorReducer(reponseStatus.default));
      dispatch(cleanListReducer(state));
    }
  };
};

const subscriptionActions = {
  createAction,
  getAllAction,
  removeAction,
  cleanState,
};
export default subscriptionActions;
