import formatDates from '../../../utils/formatDates.js';

import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  completedOrdersAttendeeDetails,
  getTrialUsers,
  getUsersHasPackageWithMonthOption,
  getUsersWithPackageAboutToEnd,
  getUsersWithPackageAndCount,
  getUsersWithPackageEnded,
} from '../../../services/apiServices/reportingServices.js';
import ActionTypes from './actionTypes.js';

const completedOrdersAttendeeDetailsAction = (filter) => {
  return async (dispatch, getState) => {
    const { completedOrdersAttendeeDetailsAction } = getState().reporting.loading;
    if (completedOrdersAttendeeDetailsAction) return;
    dispatch(handleRequestReducer({ completedOrdersAttendeeDetailsAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await completedOrdersAttendeeDetails(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ completedOrdersAttendeeDetailsAction: false }));
    }
  };
};

const getUsersWithPackageAndCountAction = (filter) => {
  return async (dispatch, getState) => {
    const { getUsersWithPackageAndCountAction } = getState().reporting.loading;
    if (getUsersWithPackageAndCountAction) return;
    dispatch(handleRequestReducer({ getUsersWithPackageAndCountAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getUsersWithPackageAndCount(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            if (response?.length > 0) {
              response = formatDates(response);
            }
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getUsersWithPackageAndCountAction: false }));
    }
  };
};

const getUsersWithPackageAboutToEndAction = (filter) => {
  return async (dispatch, getState) => {
    const { getUsersWithPackageAboutToEndAction } = getState().reporting.loading;
    if (getUsersWithPackageAboutToEndAction) return;
    dispatch(handleRequestReducer({ getUsersWithPackageAboutToEndAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getUsersWithPackageAboutToEnd(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            if (response?.length > 0) {
              response = formatDates(response);
            }
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getUsersWithPackageAboutToEndAction: false }));
    }
  };
};

const getUsersWithPackageEndedAction = (filter) => {
  return async (dispatch, getState) => {
    const { getUsersWithPackageEndedAction } = getState().reporting.loading;
    if (getUsersWithPackageEndedAction) return;
    dispatch(handleRequestReducer({ getUsersWithPackageEndedAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getUsersWithPackageEnded(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            if (response?.length > 0) {
              response = formatDates(response);
            }
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getUsersWithPackageEndedAction: false }));
    }
  };
};

const getTrialUsersAction = (filter) => {
  return async (dispatch, getState) => {
    const { getTrialUsersAction } = getState().reporting.loading;
    if (getTrialUsersAction) return;
    dispatch(handleRequestReducer({ getTrialUsersAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getTrialUsers(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            if (response?.length > 0) {
              response = formatDates(response);
            }
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getTrialUsersAction: false }));
    }
  };
};

const getUsersHasPackageWithMonthOptionAction = (filter) => {
  return async (dispatch, getState) => {
    const { getUsersHasPackageWithMonthOptionAction } = getState().reporting.loading;
    if (getUsersHasPackageWithMonthOptionAction) return;
    dispatch(handleRequestReducer({ getUsersHasPackageWithMonthOptionAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getUsersHasPackageWithMonthOption(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            if (response?.length > 0) {
              response = formatDates(response);
            }
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getUsersHasPackageWithMonthOptionAction: false }));
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.reporting.GetAll, payload: data };
};

const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.reporting.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.reporting.HandleValidation,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.reporting.HandleRequest,
    payload: data,
  };
};
const cleanListReducer = (payload) => {
  return { type: ActionTypes.reporting.CleanList, payload: payload };
};

const cleanState = (validation = false, ongoing = false, stateProp) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(cleanListReducer(stateProp));
    }
  };
};

const reportingActions = {
  completedOrdersAttendeeDetailsAction,
  getUsersWithPackageAndCountAction,
  getUsersWithPackageAboutToEndAction,
  getUsersWithPackageEndedAction,
  getTrialUsersAction,
  getUsersHasPackageWithMonthOptionAction,
  cleanState,
};
export default reportingActions;
