import config from '../../../config.json';
import { reponseStatus, responseErrorStatus, storageKeys } from '../../../constants/Enum.jsx';
import {
  create,
  getAll,
  getById,
  getFiltred,
  getSelectedMentor,
  remove,
  speakerPaymentBySpeaker,
  update,
} from '../../../services/apiServices/speakerServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const createReducer = () => {
  return { type: ActionTypes.speaker.CREATE_ACTION_SPEAKER };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.speaker.UPDATE_ACTION_SPEAKER };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await dispatch(getByIdReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.speaker.GETBYID_ACTION_SPEAKER,
    payload: { data },
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.speaker.GETALL_ACTION_SPEAKER, payload: { data } };
};

const getFilteredAction = (pageNumber, pageSize, data, keepData) => {
  return async (dispatch, getState) => {
    const { loading } = getState().speaker;
    if (loading) return;
    dispatch(handleRequestReducer(true));
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getFiltred(pageNumber, pageSize, data);
        let response = res?.data?.data;
        const pagination = JSON.parse(res?.headers['x-pagination']);
        document.cookie = storageKeys.speakerId + '=' + response?.[0]?.id + '; secure; SameSite=Lax; path=/';
        await dispatch(paginateSpeaker(pagination));
        await dispatch(getFilteredReducer(response, keepData));
        await dispatch(handleRequestReducer(false));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          await dispatch(handleRequestReducer(false));
          break;
        }
      }
    }
  };
};
const getFilteredReducer = (data, keepData) => {
  return {
    type: ActionTypes.speaker.GETFILTRED_ACTION_SPEAKER,
    payload: { data: keepData !== undefined ? [...keepData, ...data] : data },
  };
};
const speakerPaymentBySpeakerAction = () => {
  return async (dispatch) => {
    await speakerPaymentBySpeaker().then(async (res) => {
      let response = res.data.data;
      await dispatch(speakerPaymentReducer(response));
    });
  };
};

const speakerPaymentReducer = (data) => {
  return {
    type: ActionTypes.speaker.SPEAKER_PAYMENT_SPEAKER,
    payload: data,
  };
};
const paginateSpeaker = (data) => {
  return {
    type: ActionTypes.speaker.PAGINATE_SPEAKER,
    payload: data,
  };
};

const getSelectedMentorAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getSelectedMentor(data);
        let response = res?.data?.data;
        await dispatch(getSelectedMentorReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const getSelectedMentorReducer = (data) => {
  return {
    type: ActionTypes.speaker.GetSelectedMentor,
    payload: data,
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.speaker.HANDLE_ERROR_SPEAKER,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.speaker.HANDLE_VALIDATION_SPEAKER,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.speaker.HandleRequest,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.speaker.CleanList };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};

const speakerActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  getFilteredAction,
  getByIdReducer,
  speakerPaymentBySpeakerAction,
  getSelectedMentorAction,
  cleanState,
  handleError,
  handleValidationReducer,
};
export default speakerActions;
