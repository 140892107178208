import config from '../../../config.json';
import { defaultLevelGroup, storageKeys } from '../../../constants/Enum.jsx';
import { getLevelGroup, preferenceGetById } from '../../../services/apiServices/preferenceServices.js';
import ActionTypes from './actionTypes.js';

const getLevelGroupAction = (userArea) => {
  return async (dispatch) => {
    await getLevelGroup(userArea).then(async (res) => {
      let response = res?.data?.data;
      if (Object?.keys(response)?.length > 0) {
        const adultLevelGroups = response.map((item) => item.adultLanguageLevel.levelGroup);
        const kidsLevelGroups = response.map((item) => item.kidsLanguageLevel.levelGroup);
        const uniqueAdultLevelGroups = new Set(adultLevelGroups);
        const uniqueKidsLevelGroups = new Set(kidsLevelGroups);
        await dispatch(
          getLevelGroupReducer({
            adult: Array.from(uniqueAdultLevelGroups)[0],
            kids: Array.from(uniqueKidsLevelGroups)[0],
          })
        );
      } else {
        await dispatch(getLevelGroupReducer(defaultLevelGroup));
      }
    });
  };
};
const getLevelGroupReducer = (data) => {
  localStorage.setItem(storageKeys.levelGroup, JSON.stringify(data));
  return {
    type: ActionTypes.preference.GET_LEVEL_GROUP,
    payload: data,
  };
};
const getCurrencyAction = () => {
  return async (dispatch, getState) => {
    const { loading } = getState().preference;
    if (loading) return;
    dispatch(handleRequestReducer(true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await preferenceGetById(10);
        let response = res?.data?.data;
        await dispatch(getCurrencyReducer(JSON.parse(response?.value)));
        await dispatch(handleRequestReducer(false));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleRequestReducer(false));
          break;
        }
      }
    }
  };
};
const getCurrencyReducer = (data) => {
  return { type: ActionTypes.preference.GET_CURRENCY_OPTION, payload: data };
};

const setCurrencyAction = (currency, isSuccess) => {
  const modifiedCurrencyUnit = {
    code: currency?.Code || currency?.code,
    name: currency?.Name || currency?.name,
    symbol: currency?.Symbol || currency?.symbol,
    timeZone: currency?.TimeZone || currency?.timeZone,
  };
  localStorage.setItem(storageKeys.timeZone, JSON.stringify(modifiedCurrencyUnit));
  return {
    type: ActionTypes.preference.CURRENCY_CHOSEN,
    payload: { data: modifiedCurrencyUnit, isSuccess: isSuccess },
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.preference.HandleRequest,
    payload: data,
  };
};
const preferenceActions = {
  getCurrencyAction,
  setCurrencyAction,
  getLevelGroupAction,
};

export default preferenceActions;
