import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  cancelPenalty,
  changePaymentStatus,
  create,
  getMeetingsBySchemaId,
  update,
} from '../../../services/apiServices/meetingServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const createReducer = () => {
  return { type: ActionTypes.meeting.Create };
};
const updateAction = (updateData) => {
  return async (dispatch, getState) => {
    const { updateAction } = getState().meeting.loading;
    if (updateAction) return;
    dispatch(handleRequestReducer({ updateAction: true }));
    dispatch(cleanState(true, true));
    try {
      const res = await update(updateData);
      const isSuccess = res?.data?.isSuccess;
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleErrorReducer(res?.data?.isSuccess));
      if (isSuccess) {
        await dispatch(createReducer());
        await dispatch(updateReducer());
      }
    } catch (error) {
      await handleResponseError(dispatch, error);
    } finally {
      dispatch(handleRequestReducer({ updateAction: false }));
    }
  };
};
const updateReducer = () => {
  return { type: ActionTypes.meeting.Update };
};

const getMeetingsBySchemaIdAction = (filter) => {
  return async (dispatch, getState) => {
    const { getMeetingsBySchemaIdAction } = getState().meeting.loading;
    if (getMeetingsBySchemaIdAction) return;
    dispatch(handleRequestReducer({ getMeetingsBySchemaIdAction: true })); // burada request ongoing demek
    dispatch(cleanState(true, true));
    dispatch(getMeetingsBySchemaIdReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getMeetingsBySchemaId(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getMeetingsBySchemaIdReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getMeetingsBySchemaIdReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getMeetingsBySchemaIdAction: false })); // İşlem tamamlandı
    }
  };
};
const getMeetingsBySchemaIdReducer = (data) => {
  return {
    type: ActionTypes.meeting.GetMeetingsBySchemaId,
    payload: data,
  };
};

const changePaymentStatusAction = (id) => {
  return async () => {
    await changePaymentStatus(id);
  };
};

const cancelPenaltyAction = (id) => {
  return async () => {
    await cancelPenalty(id);
  };
};

const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.meeting.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.meeting.HandleValidation,
    payload: data,
  };
};
const cleanListReducer = (payload) => {
  return { type: ActionTypes.meeting.CleanList, payload: payload };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.meeting.HandleRequest,
    payload: data,
  };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(cleanListReducer());
    }
  };
};

const meetingActions = {
  cleanListReducer,
  handleErrorReducer,
  handleValidationReducer,
  cleanState,
  createAction,
  updateAction,
  getMeetingsBySchemaIdAction,
  changePaymentStatusAction,
  cancelPenaltyAction,
};
export default meetingActions;
