import ActionTypes from './actionTypes.js';

const checkScreenSize = (size) => {
  return { type: ActionTypes.screen.SCREEN_SIZE_CHECKED, payload: size };
};
const setScreenSize = (size) => {
  return { type: ActionTypes.screen.SIZE_SET, payload: size };
};
const screenActions = {
  checkScreenSize,
  setScreenSize,
};

export default screenActions;
