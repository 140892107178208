import utmDataSender from '../../../hooks/useUtmDataSender.js';

import { formActions, storageKeys } from '../../../constants/Enum.jsx';
import { payment, paymentCallback, paymentInstallment } from '../../../services/apiServices/paymentServices.js';
import ActionTypes from './actionTypes.js';

const paymentInstallmentAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await paymentInstallment(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(paymentInstallmentReducer(JSON.parse(res?.data?.data?.responseJson)));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const paymentInstallmentReducer = (data) => {
  return {
    type: ActionTypes.payment.GET_INSTALLMENT_ACTION,
    payload: { data },
  };
};
const paymentAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleError(''));
    await dispatch(handleValidationReducer(''));
    try {
      await payment(data)
        .then(async (res) => {
          if (res?.data?.data?.isSuccess) {
            if (data.paymentType === 2) {
              const storageKey = sessionStorage.getItem(storageKeys.utmData);
              if (storageKey !== null && storageKey !== '' && storageKey !== undefined) {
                utmDataSender(data.orderId, formActions.package);
              }
            }
            if (res?.data?.data?.responseJson !== null) {
              await dispatch(paymentResultReducer(true));
              await dispatch(handleError(true));
            }
            if (res?.data?.data?.callbackURL !== null && res?.data?.data?.callbackURL !== '') {
              await dispatch(handleError(true));
              await dispatch(paymentReducer(res?.data?.data?.callbackURL));
            }
          } else {
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleError(false));
          }
        })
        .catch(async (error) => {
          await dispatch(handleError(false));
        });
    } catch (error) {
      await dispatch(handleError(false));
    }
  };
};

const paymentReducer = (data) => {
  return {
    type: ActionTypes.payment.ON_PAYMENT_ACTION,
    payload: { data },
  };
};
const paymentResultReducer = (data) => {
  return {
    type: ActionTypes.payment.RESULT_PAYMENT,
    payload: { data },
  };
};
const paymentCallbackAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleError(''));
    await dispatch(handleValidationReducer(''));
    dispatch(handleRequestReducer({ paymentCallbackAction: true }));
    await paymentCallback(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        const storageKey = sessionStorage.getItem(storageKeys.utmData);
        if (storageKey !== null && storageKey !== '' && storageKey !== undefined) {
          utmDataSender(data.orderId, formActions.package);
        }
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
      dispatch(handleRequestReducer({ paymentCallbackAction: false }));
    });
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.payment.HANDLE_VALIDATION_PAYMENT,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.payment.HANDLE_ERROR_PAYMENT,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.payment.HandleRequest,
    payload: data,
  };
};
const resetStateAction = () => {
  return {
    type: ActionTypes.payment.RESET_STATE,
  };
};
const cleanState = (validation = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};
const paymentActions = {
  paymentInstallmentAction,
  paymentAction,
  paymentCallbackAction,
  resetStateAction,
  cleanState,
};
export default paymentActions;
