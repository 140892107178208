import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  create,
  getAll,
  getById,
  getLocationsByUserArea,
  remove,
  update,
} from '../../../services/apiServices/locationServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.location.CREATE_ACTION_LOCATION };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.location.UPDATE_ACTION_LOCATION };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.location.GETBYID_ACTION_LOCATION,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.location.GETALL_ACTION_LOCATION,
    payload: { data },
  };
};
const getLocationsByUserAreaAction = (area) => {
  return async (dispatch, getState) => {
    const { loading } = getState().location;
    if (loading) return;
    dispatch(handleRequestReducer(true));
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getLocationsByUserArea(area);
        let response = res?.data?.data;
        await dispatch(getLocationsByUserAreaReducer(response));
        await dispatch(handleError(''));
        await dispatch(handleRequestReducer(false));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          await dispatch(handleRequestReducer(false));
          break;
        }
      }
    }
  };
};
const getLocationsByUserAreaReducer = (data) => {
  return {
    type: ActionTypes.location.GET_BY_USER_AREA_LOCATION,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.location.HANDLE_VALIDATION_LOCATION,
    payload: data,
  };
};
const handleError = (data) => {
  return { type: ActionTypes.location.HANDLE_ERROR_LOCATION, payload: data };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.location.HandleRequest,
    payload: data,
  };
};

const cleanState = () => {
  return async (dispatch) => {
    await dispatch(getLocationsByUserAreaReducer([]));
    await dispatch(getAllReducer([]));
    await dispatch(getByIdReducer([]));
  };
};

const locationActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  getLocationsByUserAreaAction,
  cleanState,
};
export default locationActions;
