import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  attendanceCheck,
  create,
  getAll,
  getAllAttendeeSchedule,
  getAllSessionsCount,
  getAllSpeakerSchedule,
  getById,
  getMeetingAttendees,
  getSessionHistory,
  getSpeakerDropdownList,
  getTodaysMeetingBySpeaker,
  remove,
  update,
} from '../../../services/apiServices/meetingSchemaServices.js';
import ActionTypes from './actionTypes.js';

const maxRetries = config.RequestRule.retryCount;
const delay = config.RequestRule.delay;

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const createReducer = () => {
  return { type: ActionTypes.meetingSchema.Create };
};
const updateAction = (updateData) => {
  return async (dispatch, getState) => {
    const { updateAction } = getState().meetingSchema.loading;
    if (updateAction) return;
    dispatch(handleRequestReducer({ updateAction: true }));
    dispatch(cleanState(true, true));
    try {
      const res = await update(updateData);
      const isSuccess = res?.data?.isSuccess;
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleErrorReducer(res?.data?.isSuccess));
      if (isSuccess) {
        await dispatch(createReducer());
      }
    } catch (error) {
      await handleResponseError(dispatch, error);
    } finally {
      dispatch(handleRequestReducer({ updateAction: false }));
    }
  };
};
const updateReducer = () => {
  return { type: ActionTypes.meetingSchema.Update };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleErrorReducer(reponseStatus.fail));
      });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GetById,
    payload: { data },
  };
};
const getAllAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(data);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GetAll,
    payload: { data },
  };
};
const getAllAttendeeScheduleAction = (data) => {
  return async (dispatch, getState) => {
    const { getAllAttendeeScheduleAction } = getState().meetingSchema.loading;
    if (getAllAttendeeScheduleAction) return;
    dispatch(handleRequestReducer({ getAllAttendeeScheduleAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllAttendeeScheduleReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllAttendeeSchedule(data);
        let response = res?.data?.data;
        await dispatch(getAllAttendeeScheduleReducer(response));
        await dispatch(handleErrorReducer(''));
        dispatch(handleRequestReducer({ getAllAttendeeScheduleAction: false }));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          dispatch(handleRequestReducer({ getAllAttendeeScheduleAction: false }));
          break;
        }
      }
    }
  };
};
const getAllAttendeeScheduleReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEDULE,
    payload: { data },
  };
};
const getAllSessionCountAction = () => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllSessionCountReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllSessionsCount();
        let response = res?.data?.data;
        await dispatch(getAllSessionCountReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          break;
        }
      }
    }
  };
};
const getAllSessionCountReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_SESSION_COUNT_ACTION_MEETINGSCHEDULE,
    payload: { data },
  };
};
const getSpeakerDropdownListAction = (filter) => {
  return async (dispatch, getState) => {
    const { getSpeakerDropdownListAction } = getState().meetingSchema.loading;
    if (getSpeakerDropdownListAction) return;
    dispatch(handleRequestReducer({ getSpeakerDropdownListAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getSpeakerDropdownListReducer([]));
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getSpeakerDropdownList(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getSpeakerDropdownListReducer(response));
            await dispatch(handleErrorReducer(''));
          } else {
            await dispatch(getSpeakerDropdownListReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(false));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(false));
    } finally {
      dispatch(handleRequestReducer({ getSpeakerDropdownListAction: false })); // İşlem tamamlandı
    }
  };
};
const getSpeakerDropdownListReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GET_SPEAKER_DROPDOWN_LIST_ACTION,
    payload: data,
  };
};
const getTodaysMeetingBySpeakerAction = (date) => {
  return async (dispatch, getState) => {
    const { getTodaysMeetingBySpeakerAction } = getState().meetingSchema.loading;
    if (getTodaysMeetingBySpeakerAction) return;
    dispatch(handleRequestReducer({ getTodaysMeetingBySpeakerAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllTodayReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getTodaysMeetingBySpeaker(date);
        let response = res?.data?.data;
        await dispatch(getAllTodayReducer(response));
        await dispatch(handleErrorReducer(''));
        await dispatch(handleRequestReducer({ getTodaysMeetingBySpeakerAction: false }));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          await dispatch(handleRequestReducer({ getTodaysMeetingBySpeakerAction: false }));
          break;
        }
      }
    }
  };
};
const getAllTodayReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_ACTION_MEETING_BY_SPEAKER,
    payload: { data },
  };
};

const getAllSpeakerScheduleAction = (id) => {
  return async (dispatch, getState) => {
    const { getAllSpeakerScheduleAction } = getState().meetingSchema.loading;
    if (getAllSpeakerScheduleAction) return;
    dispatch(handleRequestReducer({ getAllSpeakerScheduleAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllSpeakerScheduleReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllSpeakerSchedule(id);
        let response = res?.data?.data;
        await dispatch(getAllSpeakerScheduleReducer(response));
        await dispatch(handleErrorReducer(''));
        dispatch(handleRequestReducer({ getAllSpeakerScheduleAction: false }));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          dispatch(handleRequestReducer({ getAllSpeakerScheduleAction: false }));
          break;
        }
      }
    }
  };
};
const getAllSpeakerScheduleReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GetMeetingSpeakerSchedule,
    payload: { data },
  };
};
const getMeetingAttendeesAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getMeetingAttendeesReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getMeetingAttendees(data);
        let response = res?.data?.data;
        await dispatch(getMeetingAttendeesReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          break;
        }
      }
    }
  };
};
const getMeetingAttendeesReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GET_MEETING_ATTENDEES,
    payload: { data },
  };
};
const attendanceCheckAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await attendanceCheck(data)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(getMeetingAttendeesReducer([]));
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const attendanceCheckReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.ATTENDANCE_CHECK,
    payload: { data },
  };
};

const getSessionHistoryAction = (id) => {
  return async (dispatch, getState) => {
    const { getSessionHistoryAction } = getState().meetingSchema.loading;
    if (getSessionHistoryAction) return;
    dispatch(handleRequestReducer({ getSessionHistoryAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getSessionHistoryReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getSessionHistory(id);
        let response = res?.data?.data;
        await dispatch(getSessionHistoryReducer(response));
        await dispatch(handleErrorReducer(''));
        await dispatch(handleRequestReducer({ getSessionHistoryAction: false }));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          await dispatch(handleRequestReducer({ getSessionHistoryAction: false }));
          break;
        }
      }
    }
  };
};
const getSessionHistoryReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.SessionHistory,
    payload: data,
  };
};

// success durumu
const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.HandleError,
    payload: data,
  };
};
// tüm response mesajları
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.HandleValidation,
    payload: data,
  };
};
// ilgili listeyi state'i temizle veya hepsini
const cleanListReducer = (payload) => {
  return { type: ActionTypes.meetingSchema.CleanList, payload: payload };
};
// hangi state request çıktı
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.HandleRequest,
    payload: data,
  };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};
// request ve state temizlik durumu
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      // burası modal ve submit buton için önemli
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      // default tüm state default hale gelir.
      dispatch(cleanListReducer());
    }
  };
};
const meetingSchemaActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getByIdAction,
  getByIdReducer,
  getAllAction,
  getAllReducer,
  getAllAttendeeScheduleAction,
  getAllSessionCountAction,
  getSpeakerDropdownListAction,
  cleanState,
  getTodaysMeetingBySpeakerAction,
  getAllSpeakerScheduleAction,
  getMeetingAttendeesAction,
  attendanceCheckAction,
  getSessionHistoryAction,
  cleanListReducer,
};
export default meetingSchemaActions;
