import ageIntervalActions from './ageIntervalActions.js';
import attendeeActions from './attendeeActions.js';
import attendeeFreezeActions from './attendeeFreezeActions.js';
import authActions from './authActions.js';
import bannerActions from './bannerActions.js';
import becomeAPartnerActions from './becomeAPartnerAction.js';
import branchFreezeActions from './branchFreezeActions.js';
import calendarActions from './calendarActions.js';
import campaignActions from './campaignActions.js';
import careerActions from './careerActions.js';
import classroomActions from './classroomActions.js';
import companyActions from './companyActions.js';
import companyFormActions from './companyFormActions.js';
import couponCodesActions from './couponCodesActions.js';
import faqActions from './faqActions.js';
import faqGroupActions from './faqGroupActions.js';
import feedbackActions from './feedbackActions.js';
import filterActions from './filterActions.js';
import langActions from './langActions.js';
import leadActions from './leadActions.js';
import locationActions from './locationActions.js';
import managerActions from './managerActions.js';
import meetingActions from './meetingActions.js';
import meetingAttendeeActions from './meetingAttendeeActions.js';
import meetingSchemaActions from './meetingSchemaActions.js';
import onboardActions from './onboardActions.js';
import orderActions from './orderActions.js';
import paymentActions from './paymentActions.js';
import preferenceActions from './preferenceActions.js';
import priceActions from './priceActions.js';
import reportingActions from './reportingActions.js';
import screenActions from './screenActions.js';
import sessionCategoryActions from './sessionCategoryActions.js';
import sessionPackageActions from './sessionPackageActions.js';
import speakerActions from './speakerActions.js';
import speakerAvailabilityActions from './speakerAvailabilityActions.js';
import speakerEffortActions from './speakerEffortActions.js';
import staticDataActions from './staticDataActions.js';
import subscriptionActions from './subscriptionActions.js';
import timesPerMonthActions from './timesPerMonthActions.js';
import timesPerWeekActions from './timesPerWeekActions.js';
import userActions from './userActions.js';
import userOperationClaimActions from './userOperationClaimActions.js';
import userPackageActions from './userPackageAction.js';
import wheelEventActions from './wheelEventActions.js';
import zoneActions from './zoneActions.js';

const Actions = {
  authActions,
  screenActions,
  langActions,
  careerActions,
  companyFormActions,
  faqGroupActions,
  faqActions,
  userActions,
  staticDataActions,
  speakerActions,
  onboardActions,
  couponCodesActions,
  timesPerWeekActions,
  sessionPackageActions,
  zoneActions,
  attendeeActions,
  attendeeFreezeActions,
  branchFreezeActions,
  managerActions,
  locationActions,
  ageIntervalActions,
  timesPerMonthActions,
  sessionCategoryActions,
  meetingSchemaActions,
  orderActions,
  becomeAPartnerActions,
  bannerActions,
  userPackageActions,
  calendarActions,
  meetingAttendeeActions,
  speakerAvailabilityActions,
  classroomActions,
  preferenceActions,
  subscriptionActions,
  userOperationClaimActions,
  paymentActions,
  filterActions,
  feedbackActions,
  speakerEffortActions,
  leadActions,
  priceActions,
  companyActions,
  campaignActions,
  reportingActions,
  wheelEventActions,
  meetingActions,
};

export default Actions;
