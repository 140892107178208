import config from '../../../config.json';
import { create, getAll, getById, remove, update } from '../../../services/apiServices/sessionPackageServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.sessionPackage.CREATE_ACTION_SESSIONPACKAGE };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.sessionPackage.UPDATE_ACTION_SESSIONPACKAGE };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.sessionPackage.GETBYID_ACTION_SESSIONPACKAGE,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch, getState) => {
    const { loading } = getState().sessionPackage;
    if (loading) return;
    dispatch(handleRequestReducer(true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll();
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        await dispatch(handleRequestReducer(false));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleError(false));
          await dispatch(handleRequestReducer(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.sessionPackage.GETALL_ACTION_SESSIONPACKAGE,
    payload: { data },
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.sessionPackage.HANDLE_ERROR_SESSIONPACKAGE,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.sessionPackage.HandleRequest,
    payload: data,
  };
};
const sessionPackageActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  handleError,
};
export default sessionPackageActions;
