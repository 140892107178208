import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import { priceCalculate } from '../../../services/apiServices/priceServices.js';
import ActionTypes from './actionTypes.js';

const priceCalculateAction = (updateData) => {
  return async (dispatch, getState) => {
    const { priceCalculateAction } = getState().price.loading;
    if (priceCalculateAction) return;
    dispatch(handleRequestReducer({ priceCalculateAction: true }));
    dispatch(cleanState(true, true));
    try {
      const res = await priceCalculate(updateData);
      await dispatch(priceCalculateReducer(res.data.data));
      if (!res?.data?.isSuccess) {
        await dispatch(handleValidationReducer(res?.data?.message));
      }

      await dispatch(handleErrorReducer(res?.data?.isSuccess));
    } catch (error) {
      await handleResponseError(dispatch, error);
    } finally {
      dispatch(handleRequestReducer({ priceCalculateAction: false }));
    }
  };
};

const priceCalculateReducer = (data) => {
  return {
    type: ActionTypes.price.CalculatedPrices,
    payload: data,
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.price.HandleValidation,
    payload: data,
  };
};
const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.price.HandleError,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.price.HandleRequest,
    payload: data,
  };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};

const cleanState = (validation = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(''));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(''));
      dispatch(priceCalculateReducer([]));
    }
  };
};
const priceActions = {
  priceCalculateAction,
  cleanState,
};
export default priceActions;
