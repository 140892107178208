import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  createSpeakerAvailability,
  getAllSpeakerAvailabilities,
  getSelectedSpeakerDates,
  removeSpeakerAvailability,
  updateSpeakerAvailability,
} from '../../../services/apiServices/speakerAvailabilityServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await createSpeakerAvailability(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const createReducer = () => {
  return { type: ActionTypes.speakerAvailability.Create };
};

const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await updateSpeakerAvailability(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};

const updateReducer = () => {
  return { type: ActionTypes.speakerAvailability.Update };
};

const removeAction = (id) => {
  return async () => {
    await removeSpeakerAvailability(id);
  };
};

const getAllAction = (requestType) => {
  return async (dispatch) => {
    await getAllSpeakerAvailabilities(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};

const getAllReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.GetAll,
    payload: { data },
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.speakerAvailability.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.HandleValidation,
    payload: data,
  };
};
const getSelectedAvaibilitiesAction = (filter) => {
  return async (dispatch) => {
    await getSelectedSpeakerDates(filter).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.code === 200) {
        await dispatch(getSelectedAvaibilitiesReducer(response));
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const getSelectedAvaibilitiesReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.GetSelectedSpeakerDates,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const speakerAvailabilityActions = {
  createAction,
  updateAction,
  removeAction,
  getAllAction,
  handleError,
  getSelectedAvaibilitiesAction,
  cleanState,
};

export default speakerAvailabilityActions;
