import utmDataSender from '../../../hooks/useUtmDataSender.js';

import config from '../../../config.json';
import { formActions, reponseStatus, responseErrorStatus, storageKeys } from '../../../constants/Enum.jsx';
import { create, getAll, getById, update } from '../../../services/apiServices/leadServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          const storageKey = sessionStorage.getItem(storageKeys.utmData);
          if (storageKey !== null && storageKey !== '' && storageKey !== undefined) {
            utmDataSender(createData, formActions.campaign);
          }
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const createReducer = () => {
  return { type: ActionTypes.lead.Create };
};

const updateAction = (updateData) => {
  return async (dispatch) => {
    dispatch(cleanState(true));
    await update(updateData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.lead.GetById,
    payload: data,
  };
};
const getAllAction = (filterData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(filterData);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.lead.GetAll, payload: data };
};

const handleError = (data) => {
  return {
    type: ActionTypes.lead.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.lead.HandleValidation,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.lead.CleanList };
};

const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};

const leadActions = {
  createAction,
  getAllAction,
  updateAction,
  getByIdAction,
  cleanState,
};
export default leadActions;
