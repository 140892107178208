import i18next from 'i18next';

import { Flags, Languages, LanguagesForSite, storageKeys } from '../../../constants/Enum.jsx';
import ActionTypes from './actionTypes.js';

const pickLanguage = (lang) => {
  let longLang = '';
  let newLang = '';
  switch (true) {
    case lang.includes('en'):
      longLang = Languages.eng;
      newLang = LanguagesForSite.eng;
      break;
    case lang.includes('tr'):
      newLang = LanguagesForSite.tr;
      longLang = Languages.tr;
      break;
    default:
      longLang = Languages.eng;
      newLang = LanguagesForSite.eng;
      break;
  }
  localStorage.setItem(storageKeys.languge, newLang);
  i18next.changeLanguage(newLang);
  return {
    type: ActionTypes.lang.LANGUAGE_CHOSEN,
    payload: { newLang, longLang },
  };
};

const setFlag = (lang) => {
  let flag = '';
  switch (true) {
    case lang.includes('en'):
      flag = Flags.greatBritain;
      break;
    case lang.includes('tr'):
      flag = Flags.turkey;
      break;
    default:
      flag = Flags.greatBritain;
      break;
  }
  return { type: ActionTypes.lang.SET_FLAG, payload: flag };
};
const langActions = {
  pickLanguage,
  setFlag,
};

export default langActions;
